* {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

/* Basic Formatting */
body {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: #333;
    color: white;
    height: 100vh;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: white;
    border-radius: 10px;
}
.center h1 {
    text-align: center;
    padding: 0 0 20px 0;
    border-bottom: 1px solid silver;
    color: black;
}
.center form {
    padding: 0 40px;
    box-sizing: border-box;
}

form .search {
    margin-bottom: 10px;
    margin-left: 2px;
    padding-left: 5px;
    padding-right: 5px
}

form .txt {
    position: relative;
    margin: 30px 0;
    text-align: center;
    color: black;
}
form .txt_field {
    position: relative;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
    text-align: center;
    color: black;
}
.txt_field input {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
}
.txt_field label {
    position: absolute;
    top: 50%;
    left: 5px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: 0.5s;
}
.txt_field span::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    background: #333;
    transition: 0.5s;
}
.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
    top: -5px;
    color: #333;
}
.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before {
    width: 100%;
}

.top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 50px;
    width: 50px;
    font-size: 50px;
}

/* Loading Page */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader::after {
    content: "";
    width: 100px;
    height: 100px;
    border: 15px solid #dddddd;
    border-top-color: #666;
    border-radius: 50%;
    animation: loading 0.75s ease infinite;
}
@keyframes loading {
    from { transform: rotate(0turn) }
    to { transform: rotate(1turn) }
}

/* Home Page */
body .home {
    min-height: 105vh;
    background: linear-gradient(#2b1055, #7597de);
    scroll-behavior: smooth;
}

section::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to top, #333, transparent);
    z-index: 11;
}
section {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
section img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 106%;
    object-fit: cover;
    pointer-events: none;
}
section img#moon {
    mix-blend-mode: screen;
}
section img#mountain_front {
    z-index: 10;
}
section h2#text {
    position: absolute;
    right: -350px;
    font-weight: bold;
    color: white;
    white-space: nowrap;
    font-size: 7.5vw;
    z-index: 9;
}
section #btn {
    text-decoration: none;
    display: inline-block;
    padding: 8px 30px;
    border-radius: 40px;
    background-color: white;
    color: #2b1055;
    font-size: 1.5em;
    z-index: 9;
    transform: translateY(100px);
}

.sec {
    position: relative;
    padding: 100px;
    background: #333;
}
.sec h2 {
    font-size: 3.5rem;
    margin-bottom: 10px;
    color: white;
}
.sec p {
    font-size: 1.2em;
    color: white;
}

/* Logout */
button[type="submit"] {
    width: 100%;
    height: 50px;
    border: 1px solid;
    margin-bottom: 10px;
    background: #333;
    color: white;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    outline: none;
}
button[type="submit"]:hover {
    border-color: #333;
    transition: 0.5s;
}

/* Navbar UI */
.nav {
    background-color: #181818;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
}

.site-title {
    font-size: 2.5rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    font-size: 20px;
    list-style: none;
    display: flex;
    gap: 2rem;
}
.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}
.nav li:hover {
    background-color: #777;
}

/* Bazaar UI */
.wrapper {
    width: 52.2vw;
    margin: 50px auto;
}

.links {
    margin-bottom: 20px;
    background: #777;
    padding: 15px;
    border-radius: 3px;
}
.links ul {
    margin: auto;
    display: flex;
    justify-content: center;
}
.links ul li {
    margin: 0 15px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 3px;
    cursor: pointer;
}

.links ul li:hover,
.links ul li:active {
    color: #333;
}

.view_item img {
    width: 75px;
    height: 75px;
}
.list-view .view_item img {
    margin-right: 20px;
}

.view_main {
    background: #555;
    border-radius: 3px;
    padding: 15px;
}

.list-view .view_item {
    border: 1px solid white;
    margin: 10px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}
.list-view .view-item:last-child {
    margin-bottom: 0;
}
.list-view .view-item .vi_left {
    margin-right: 25px;
}

.view_item .title {
    font-weight: 600;
    text-decoration: none;
}
.view_item .name {
    font-weight: 600;
    font-size: 2rem;
    text-decoration: none;
}
.view_item strong {
    font-weight: 600;
}
.view_item .content {
    margin: 5px 0;
    font-size: 14px;
    line-height: 22px;
    font-weight: 200;
}
.view_item .id {
    margin: 5px 0;
    font-size: 11px;
    line-height: 22px;
}
.view_item .btn {
    width: 125px;
    background: #333;
    color: white;
    border-radius: 3px;
    padding: 4px 4px;
    text-align: center;
    cursor: pointer;
}
.view_item .btn:hover {
    background: #222;
}

.grid-view {
    width: 100%;
}
.grid-view .view_item{
	display: inline-block;
    border: 1px solid white;
    width: 230px;
    padding: 25px;
    text-align: center;
    margin: 10px;
}
.grid-view .view_item .vi_left{
	margin-bottom: 10px;
}
.grid-view .view_item .btn{
	margin: 0 auto;
}